import * as React from "react";
import styled from "@emotion/styled";

const layoutWidth = 1280;
const layoutPadding = '1em';
const ContentBlock = styled.div`
  padding: 0 ${layoutPadding};
  max-width: ${layoutWidth}px;
  margin: 0 auto;
`;
// @todo DRY
const TextBlock = styled.div`
    /* text-align: justify; */

    &:after {
        content: ".";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }

    > * + * {
        margin-top: 1em;
    }

    p {
        color: rgba(0, 0, 0, .5);
        font-size: .6em;
    }

    a {
        border-bottom: 1px solid;

        &:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, .5);
        }
    }

    strong {
        font-weight: bold;
    }
`;
const imagesListSpacing = '.5em';
const imagesColumns = 4;
const ImagesList = styled.ul`
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -${imagesListSpacing} -${imagesListSpacing} 0 0;

  > li {
    margin: ${imagesListSpacing} ${imagesListSpacing} 0 0;
    /* width: calc((${layoutWidth}px - 2 * ${layoutPadding} - ((${imagesColumns} - 1) * ${imagesListSpacing})) / ${imagesColumns}); */
  }

  img {
    display: block;
    width: 100%;
    max-height: 200px;
    transition: transform .2s;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, .25);

    &:hover {
      transform: scale(1.1);
    }
  }
`;
const ImagesList2 = styled.ul`
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin: -${imagesListSpacing} -${imagesListSpacing} 0 0;

  > li {
    margin: ${imagesListSpacing} ${imagesListSpacing} 0 0;
    /* width: calc((${layoutWidth}px - 2 * ${layoutPadding} - ((${imagesColumns} - 1) * ${imagesListSpacing})) / ${imagesColumns}); */

  }

  img {
    display: block;
    /* width: 100%; */
    /* height: 100%; */
    width: 200px;
    height: 200px;
    object-fit: cover;
    /* transition: transform .2s; */
    /* box-shadow: 3px 3px 10px rgba(0, 0, 0, .25); */

    &:hover {
      /* transform: scale(1.1); */
    }
  }
`;
const ImagesGrid = styled.div`
    display: grid;
    /* grid-template-rows: repeat(8, 5vw); */
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1em;

    img {
        display: block;
        width: 100%;
        height: 100%;
        /* width: 350px;
        height: 350px; */
        object-fit: cover;
    }
`;

const images3d = [
    'clownfish_1_square.png',
    // 'mineral_cube.gif',
    // 'planet.gif',
    'small_grid13_square.png',
    'crystals14_square.png',
    'mineral cube collage 3_square.png',
    'pig hex_pigs_7_square.png',
    'planet hns game assets 2 v9 1024x1024_square.png',
    'low poly penguin 2 v11_square.png',
    'rhino scene 9_square.png',
    'low-poly4-hires_square.png',
];
const images2d = [
    "planet-4_square.png",
    "planet-6_square.png",
    "planet-8_square.png",
    "trees-showoff_square.png",
    "sheep Screenshot (147)_square.png",
    // "sheep Screenshot (142)_square.png",
    "sheep Screenshot (140)_square.png",
    // "sheep Screenshot (144)_square.png",
    "sheep Screenshot (145)_square.png",
    "sheep Screenshot (146)_square.png",
    'characters-collage_square.png',
    '2d-scenes-collage_square.jpg',
    'clouds-collage_square.jpg',
    'patterns-collage_square.jpg',
    '2018-06-07 meteor_square.png',
    'clouds2_square.png',
    'clouds_square.png',
    'nebulas_square.png',
    'pokemon DSC_0002_edit1_square.JPG',
    'pokemon DSC_0001_edit1_square.JPG',
];
const imagesProcgen = [
    'screenshot_3840x2160_2020-02-22_18-32-43_square.png',
    'screenshot_3840x2160_2020-02-22_20-05-41_square.png',
    'screenshot_3840x2160_2020-02-22_19-43-51_square.png',
    'procgen-patterns-collage-3_square.png',
    'procgen-patterns-collage-2_square.png',
    'procgen-patterns-collage-1_square.png',
    'terrains-collage-2_square.png',
    'terrains-collage-1_square.png',
];

const ImagesBlockWrapper = styled.div`
    > * + * {
        margin-top: 1em;
    }
`;
const VideoBlock = styled.div`
    background-color: #eee;

    video {
        max-width: 640px;
        margin: 0 auto;
        display: block;
    }
`;
const ImagesBlockHeader = styled.h3`
    color: rgba(0, 0, 0, .5);
    font-size: .75em;
    line-height: 1;
    padding-bottom: .25em;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 1em;
    margin-top: 2em;
`;

function ImagesBlock(props: {imageGroup: ImageGroup; max?: number}) {
    const {imageGroup} = props;

    return (
        <ImagesBlockWrapper>
            <ImagesBlockHeader>{imageGroup.title}</ImagesBlockHeader>

            {
                imageGroup.gfycatId
                &&
                <VideoBlock>
                    <video
                        autoPlay={false}
                        playsInline={false}
                        preload="auto"
                        poster={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.jpg`}
                        tabIndex={-1}
                        // width={1600}
                        // height={900}
                        controls={true}
                    >
                        <source src={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.mp4`} type="video/mp4" />
                        <source src={`https://giant.gfycat.com/${imageGroup.gfycatId}.webm`} type="video/webm" />
                        <source src={`https://giant.gfycat.com/${imageGroup.gfycatId}.mp4`} type="video/mp4" />
                        <source src={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.mp4`} type="video/mp4" />
                    </video>
                </VideoBlock>
            }

            <ImagesGrid>
                {
                    imageGroup.paths.slice(0, props.max || imageGroup.paths.length).map((imgPath, i) => {
                        return (
                            <div key={imgPath}>
                                <img
                                    loading="lazy"
                                    src={`${imageGroup.basePath}${imgPath}`}
                                    width={300}
                                    height={300}
                                />
                            </div>
                        );
                    })
                }
            </ImagesGrid>
        </ImagesBlockWrapper>
    );
}

interface ImageGroup {
    title: string;
    basePath: string;
    paths: string[];
    gfycatId?: string;
    Content?: () => JSX.Element;
}

const imageGroups: ImageGroup[] = [
    {
        title: "3D (Blender)",
        basePath: '/images/3d/',
        paths: images3d,
    },
    {
        title: "Drawing & painting",
        basePath: '/images/2d/',
        paths: images2d,
    },
    {
        title: "Procedural generation",
        basePath: '/images/projects/',
        gfycatId: 'DiligentDaringHairstreakbutterfly',
        paths: imagesProcgen,
    },
];

export default function GraphicalArtsBlock(props: {maxImages?: number; content?: React.FC}) {
    const {content: Content} = props;

    return (
        <div>
            <div style={{ backgroundColor: '#fff' }}>
                <div style={{  }}>
                    <TextBlock>
                        <h2>Graphic art</h2>

                        {
                            imageGroups.map(imageGroup => {
                                return (
                                    <ImagesBlock
                                        key={imageGroup.title}
                                        imageGroup={imageGroup}
                                        max={props.maxImages}
                                    />
                                );
                            })
                        }

                        {
                            Content
                            &&
                            <Content />
                        }
                    </TextBlock>
                </div>
            </div>

            {/* <div style={{ backgroundColor: '#fff' }}>
                <ContentBlock>
                    <div style={{ padding: '2em 0' }}>
                        <TextBlock>
                            <p>I periodically come back to make some digital art in a form of 3D renders or 2D digital paintings.</p>
                            <p>isometric, progress gifs, grids (quads/hexes), models (penguin, elephant, rhino, pig, fish), colors</p>

                            <div>
                                <ImagesList>
                                    {
                                        images3d.map((imgPath, i) => {
                                            return <li key={imgPath}><img src={`/images/3d/${imgPath}`} /></li>
                                        })
                                    }
                                </ImagesList>
                            </div>
                        </TextBlock>
                    </div>
                </ContentBlock>
            </div>

            <div style={{ backgroundColor: '#bbb' }}>
                <ContentBlock>
                    <div style={{ padding: '2em 0' }}>
                        <TextBlock>
                            <p>These years I tend to spend more time creating in programming environments like game engines than in graphics editors.</p>
                            <p>Besides 2d and 3d graphics, I also work on projects that often feature fair amount of procedural generation using either a game engine like Unity or JavaScript in the browser.</p>
                            <p>I'm also interested in procedural generation which I tend to implement in Unity and then export and render in Blender.</p>

                            <div>
                                <ImagesList>
                                    {
                                        imagesProject.map((imgPath, i) => {
                                            return <li key={i}><img src={`/images/projects/${imgPath}`} /></li>
                                        })
                                    }
                                </ImagesList>
                            </div>

                            <p>I experiment with printing my procgen results.</p>

                            <div>
                                <ImagesList>
                                    {
                                        Array(8).fill(0).map((n, i) => {
                                            return <li key={i}><img src={`https://source.unsplash.com/random/320x240?t=${i}`} /></li>
                                        })
                                    }
                                </ImagesList>
                            </div>
                        </TextBlock>
                    </div>
                </ContentBlock>
            </div>

            <div style={{ backgroundColor: '#fff' }}>
                <ContentBlock>
                    <div style={{ padding: '2em 0' }}>
                        <TextBlock>
                            <p>I periodically come back to make some digital paintings.</p>
                            <p>clouds, patterns, landscapes, characters, colors, less rigid, more flexible</p>

                            <div>
                                <ImagesList>
                                    {
                                        images2d.map((imgPath, i) => {
                                            return <li key={imgPath}><img src={`/images/2d/${imgPath}`} /></li>
                                        })
                                    }
                                </ImagesList>
                            </div>

                            <p>I share my digital art on <a href="https://www.artstation.com/lukaszgrolik">ArtStation</a>, <a href="https://twitter.com/LukGallery">Twitter</a>, <a href="https://imgur.com/user/lukg">Imgur</a> and <a href="https://www.instagram.com/_lukg_/">Instagram</a>.</p>
                        </TextBlock>
                    </div>
                </ContentBlock>
            </div>

            <div style={{ backgroundColor: '#bbb' }}>
                <ContentBlock>
                    <div style={{ padding: '2em 0' }}>
                        <TextBlock>
                            <p>I like to visit green areas nearby and make photos of nature.</p>

                            <div>
                                <ImagesList>
                                    {
                                        photosNature.map((imgPath, i) => {
                                            return <li key={imgPath}><img src={`/images/photos/nature/${imgPath}`} /></li>
                                        })
                                    }
                                </ImagesList>
                            </div>

                            <p>I like to discover patterns and shapes ..</p>

                            <div>
                                <ImagesList>
                                    {
                                        photosPatterns.map((imgPath, i) => {
                                            return <li key={imgPath}><img src={`/images/photos/patterns/${imgPath}`} /></li>
                                        })
                                    }
                                </ImagesList>
                            </div>

                            <p>I like to create timelapses that show underlying processes.</p>

                            <div>
                                <ImagesList>
                                    {
                                        photosPatterns.map((imgPath, i) => {
                                            return <li key={imgPath}><img src={`/images/photos/patterns/${imgPath}`} /></li>
                                        })
                                    }
                                </ImagesList>
                            </div>

                            <p>I share my photos on <a href="https://twitter.com/LukGallery">Twitter</a>, <a href="https://imgur.com/user/lukg">Imgur</a> and <a href="https://www.instagram.com/_lukg_/">Instagram</a>.</p>
                        </TextBlock>
                    </div>
                </ContentBlock>
            </div> */}
        </div>
    );
}
