import * as React from "react";
import styled from "@emotion/styled";

const layoutWidth = 1280;
const layoutPadding = '1em';
const ContentBlock = styled.div`
  padding: 0 ${layoutPadding};
  max-width: ${layoutWidth}px;
  margin: 0 auto;
`;
// @todo DRY
const TextBlock = styled.div`
/* text-align: justify; */

    &:after {
        content: ".";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }

    > * + * {
        margin-top: 1em;
    }

    p {
        color: rgba(0, 0, 0, .5);
        font-size: .6em;
    }

    a {
        border-bottom: 1px solid;

        &:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, .5);
        }
    }

    strong {
        font-weight: bold;
    }
`;
const imagesListSpacing = '.5em';
const imagesColumns = 4;
const ImagesList = styled.ul`
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -${imagesListSpacing} -${imagesListSpacing} 0 0;

  > li {
    margin: ${imagesListSpacing} ${imagesListSpacing} 0 0;
    /* width: calc((${layoutWidth}px - 2 * ${layoutPadding} - ((${imagesColumns} - 1) * ${imagesListSpacing})) / ${imagesColumns}); */
  }

  img {
    display: block;
    width: 100%;
    max-height: 200px;
    transition: transform .2s;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, .25);

    &:hover {
      transform: scale(1.1);
    }
  }
`;
const ImagesList2 = styled.ul`
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin: -${imagesListSpacing} -${imagesListSpacing} 0 0;

  > li {
    margin: ${imagesListSpacing} ${imagesListSpacing} 0 0;
    /* width: calc((${layoutWidth}px - 2 * ${layoutPadding} - ((${imagesColumns} - 1) * ${imagesListSpacing})) / ${imagesColumns}); */

  }

  img {
    display: block;
    /* width: 100%; */
    /* height: 100%; */
    width: 200px;
    height: 200px;
    object-fit: cover;
    /* transition: transform .2s; */
    /* box-shadow: 3px 3px 10px rgba(0, 0, 0, .25); */

    &:hover {
      /* transform: scale(1.1); */
    }
  }
`;
const ImagesGrid = styled.div`
    display: grid;
    /* grid-template-rows: repeat(8, 5vw); */
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1em;

    img {
        display: block;
        width: 100%;
        height: 100%;
        /* width: 350px;
        height: 350px; */
        object-fit: cover;
    }
`;

const photosNature = [
    "2020-11-23 DSC_0008_edit1_square.JPG",
    "2020-11-20 DSC_0047_edit1_square.JPG",
    "2020-11-20 DSC_0044_edit1_square.jpg",
    "2020-09-12 DSC_0094_square.JPG",
    "2020-09-12 DSC_0085_square.JPG",
    "2020-09-12 DSC_0060_square.JPG",
    "2020-09-12 DSC_0053_square.JPG",
    "2020-09-10 DSC_0007_square.JPG",
    "2020-08-29 DSC_0063_square.JPG",
    "2020-08-20 DSC_0106_square.JPG",
    "2020-08-20 DSC_0067_square.JPG",
    "2020-08-17 DSC_0073_square.JPG",
    "2020-08-17 DSC_0025_square.JPG",
    "2020-07-29 DSC_0041_crop1_square.JPG",
    '2020-07-29 DSC_0008_edit1_square.JPG',
    "2020-07-28 DSC_0027_edit1_square.JPG",
    '2020-07-27 DSC_0025_orig_square.JPG',
    "2020-07-25 DSC_0148_edit1_crop2_square.JPG",
    "2020-07-25 DSC_0104_edit1_crop1_square.JPG",
    "2020-07-24 DSC_0030_edit1_crop2_square.JPG",
    "2020-07-10 DSC_0090_edit1_crop1_square.JPG",
    "2020-06-25 DSC_0059_edit2_crop1_square.JPG",
    "2020-06-23 DSC_0145_edit1_square.JPG",
    "2020-06-17 DSC_0091_edit1_crop1_square.JPG",
    "2020-06-16 DSC_0244_square.JPG",
    "2020-06-16 DSC_0218_square.JPG",
    "2020-06-16 DSC_0214_square.JPG",
    '2020-06-10 DSC_0152_edit1_crop1_square.JPG',
    '2020-06-07 DSC_0098_edit1_crop1_square.JPG',
    '2020-06-06 DSC_0112_edit1_square.JPG',
    '2020-06-05 DSC_0073_edit3_square.JPG',
    '2020-06-05 DSC_0073_edit2_square.JPG',
    '2020-06-05 DSC_0034_edit3_crop1_square.JPG',
    '2020-06-05 DSC_0033_edit5_square.JPG',
    '2020-06-04 DSC_0392_edit1_square.JPG',
    '2020-06-01 DSC_0297_edit1_square.JPG',
    '2020-05-27 DSC_0025_edited_square.JPG',
];
const photosPatterns = [
    '2020-06-04 DSC_0383_edit1.JPG',
    '2020-06-04 DSC_0391_edit1.JPG',
    '2020-06-07 DSC_0008_edit2.JPG',
    '2020-06-07 DSC_0068_edit1.JPG',
    '2020-06-09 DSC_0044_edit1.JPG',
    '2020-06-13 DSC_0025_edit1.JPG',
    '2020-06-16 DSC_0154_edit1.JPG',
    '2020-06-16 DSC_0249_edit1.JPG',
];

const ImagesBlockWrapper = styled.div`
    > * + * {
        margin-top: 1em;
    }
`;
const VideoBlock = styled.div`
    background-color: #eee;

    video {
        max-width: 640px;
        margin: 0 auto;
        display: block;
    }
`;
const ImagesBlockHeader = styled.h3`
    color: rgba(0, 0, 0, .5);
    font-size: .75em;
    line-height: 1;
    padding-bottom: .25em;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    margin-bottom: 1em;
`;

function ImagesBlock(props: {imageGroup: ImageGroup; max?: number}) {
    const {imageGroup} = props;

    return (
        <ImagesBlockWrapper>
            {/* <ImagesBlockHeader>{props.title}</ImagesBlockHeader> */}

            {
                imageGroup.gfycatId
                &&
                <VideoBlock>
                    <video
                        autoPlay={false}
                        playsInline={false}
                        preload="auto"
                        poster={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.jpg`}
                        tabIndex={-1}
                        // width={1600}
                        // height={900}
                        controls={true}
                    >
                        <source src={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.mp4`} type="video/mp4" />
                        <source src={`https://giant.gfycat.com/${imageGroup.gfycatId}.webm`} type="video/webm" />
                        <source src={`https://giant.gfycat.com/${imageGroup.gfycatId}.mp4`} type="video/mp4" />
                        <source src={`https://thumbs.gfycat.com/${imageGroup.gfycatId}-mobile.mp4`} type="video/mp4" />
                    </video>
                </VideoBlock>
            }

            <ImagesGrid>
                {
                    imageGroup.paths.slice(0, props.max || imageGroup.paths.length).map((imgPath, i) => {
                        return (
                            <div key={imgPath}>
                                <img
                                    loading="lazy"
                                    src={`${imageGroup.basePath}${imgPath}`}
                                    width={300}
                                    height={300}
                                />
                            </div>
                        );
                    })
                }
            </ImagesGrid>
        </ImagesBlockWrapper>
    );
}

interface ImageGroup {
    title: string;
    basePath: string;
    paths: string[];
    gfycatId?: string;
    Content?: () => JSX.Element;
}

const imageGroups: ImageGroup[] = [
    {
        title: "Photography",
        basePath: '/images/photos/',
        gfycatId: 'LikableWebbedFly',
        paths: photosNature,
    },
];

export default function PhotographyBlock(props: {maxImages?: number; content?: React.FC}) {
    const {content: Content} = props;

    return (
        <div>
            <div style={{ backgroundColor: '#fff' }}>
                <div style={{  }}>
                    <TextBlock>
                        <h2>Photography</h2>

                        {
                            imageGroups.map(imageGroup => {
                                return (
                                    <ImagesBlock
                                        key={imageGroup.title}
                                        imageGroup={imageGroup}
                                        max={props.maxImages}
                                    />
                                );
                            })
                        }

                        {
                            Content
                            &&
                            <Content />
                        }
                    </TextBlock>
                </div>
            </div>
        </div>
    );
}
